import React, { useState } from "react";

import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";

import "@fontsource/poppins";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "../styles/global.css";
import Header from "./ui/Header/Header";
import Footer from "./ui/Footer/Footer";
import MobileDrawer from "./ui/Header/MobileDrawer";

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [showMobileNavDrawer, setShowMobileNavDrawer] = useState(false);

  const handleMobileNavClose = () => {
    setShowMobileNavDrawer(false);
  };

  const handleMobileNavOpen = () => {
    setShowMobileNavDrawer(true);
  };
  return (
    <ThemeProvider theme={theme}>
      <div id="app">
        <Header handleOpenMobileNav={handleMobileNavOpen} />
        <MobileDrawer
          showMobileNav={showMobileNavDrawer}
          handleCloseMobileNav={handleMobileNavClose}
        />
        <main className="overflow-hidden">{children}</main>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default Layout;
