import { IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import HeaderNotice from "./headerNotice";

interface HeaderProps {
  handleOpenMobileNav: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleOpenMobileNav }) => {
  return (
    <header>
      <HeaderNotice />
      <div>
        <div className="container">
          <div className="flex items-center justify-between h-28 overflow-hidden">
            <div>
              <Link
                to="/"
                className="sm:text-2xl text-xl font-bold text-text-heading"
              >
                <StaticImage
                  src="../../../../static/images/PSD-logo-final.png"
                  alt="Premier Stucco Design"
                  layout="fixed"
                  placeholder="tracedSVG"
                  height={80}
                />
              </Link>
            </div>
            <ul className="sm:flex hidden">
              <ListItem to="/">Home</ListItem>
              <ListItem to="/about/">About Us</ListItem>
              <ListItem to="/services/">Services</ListItem>
              <ListItem to="/projects/">Projects</ListItem>
              <ListItem to="/contact/">Contact</ListItem>
            </ul>
            <IconButton className="sm:hidden" onClick={handleOpenMobileNav}>
              <Menu />
            </IconButton>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

interface ListItemProps {
  to: string;
}

const ListItem: React.FC<ListItemProps> = ({ to, children }) => {
  return (
    <li className="mr-5 last:mr-0">
      <Link
        className="font-medium text-text-heading hover:text-primary-main"
        activeClassName="text-primary-main"
        to={to}
      >
        {children}
      </Link>
    </li>
  );
};
