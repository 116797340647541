import { Instagram, Facebook } from "@material-ui/icons";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import contactInfo from "../../../content/settings/contact_info.json";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <footer className="bg-black py-[50px]">
      <div className="container ">
        <div className="flex sm:justify-evenly flex-wrap">
          <div className="lg:w-auto sm:w-1/3 sm:mb-0 mb-2">
            <StaticImage
              src="../../../../static/images/PSD-logo-final.png"
              alt="Premier Stucco Design"
              layout="fixed"
              height={90}
              placeholder="tracedSVG"
            />
            {/* <h3 className="text-2xl text-white">Premier Stucco Design</h3> */}
          </div>
          <div className="max-w-xs mb-4 sm:mb-0">
            <h5 className="sm:text-xl text-lg mb-2 font-semibold text-white">
              Contact
            </h5>
            <ul>
              <FooterListItem title="Address">
                <span className="mr-1">{contactInfo.address.street}</span>
                <span className="mr-1">, {contactInfo.address.city}</span>
                <span className="mr-1">, {contactInfo.address.zipCode}</span>
                <span>, {contactInfo.address.state}</span>
              </FooterListItem>

              <FooterListItem title="Phone">
                <a
                  className="hover:text-primary-main"
                  href={`tel:${contactInfo.phone}`}
                >
                  {formatPhoneNumber(contactInfo.phone)}
                </a>
              </FooterListItem>

              <FooterListItem title="Email">
                <a
                  className="hover:text-primary-main"
                  href={`mailto:${contactInfo.email}`}
                >
                  {contactInfo.email}
                </a>
              </FooterListItem>
            </ul>
          </div>
          <div>
            <h5 className="text-lg sm:text-xl mb-2 font-semibold text-white">
              Follow
            </h5>
            <ul className="flex items-center">
              <li>
                <a
                  href={contactInfo.socialMedia.instagram}
                  target="__blank"
                  className="text-gray-400 hover:text-gray-50"
                >
                  <Instagram color="inherit" />
                </a>
              </li>
              <li className="ml-2">
                <a
                  href={contactInfo.socialMedia.facebook}
                  target="__blank"
                  className="text-gray-400 hover:text-gray-50"
                >
                  <Facebook />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="bg-gray-600 h-[1px] w-[70%] mx-auto my-8" />

        <div className="w-full text-center text-sm">
          <span>&copy; Copyright 2023. All rights reserved.</span>
        </div>

        <div className="w-full text-center mt-2">
          <a
            href="https://www.bestinottawa.com/"
            target="__blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../../../static/images/best-in-ottawa.jpg"
              alt="Best in Ottawa"
              layout="fixed"
              height={90}
              placeholder="tracedSVG"
              className="mx-auto"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

interface FooterListItemProps {
  title: string;
}

const FooterListItem: React.FC<FooterListItemProps> = ({ title, children }) => {
  return (
    <li className="mb-1 last:mb-0">
      <span className="text-white mr-1">{title}:</span>
      <span className="text-gray-400">{children}</span>
    </li>
  );
};
