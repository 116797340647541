import React from "react";

import { Button } from "@material-ui/core";
import { ArrowRightAlt, Facebook, Instagram } from "@material-ui/icons";
import { Link } from "gatsby";

import contactInfo from "../../../content/settings/contact_info.json";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";

interface HeaderNoticeProps {}

const HeaderNotice: React.FC<HeaderNoticeProps> = ({}) => {
  return (
    <div className="border-b">
      <div className="container">
        <div className="flex items-center justify-between">
          <ul className="sm:flex hidden">
            <li className="pr-3">
              <span className="mr-1">Phone:</span>
              <a
                className="font-medium hover:text-primary-main"
                href={`tel:${contactInfo.phone}`}
              >
                {formatPhoneNumber(contactInfo.phone)}
              </a>
            </li>

            <li>
              <span className="mr-1">Email:</span>
              <a
                className="font-medium hover:text-primary-main"
                href={`mailto:${contactInfo.email}`}
              >
                {contactInfo.email}
              </a>
            </li>
          </ul>
          <ul className="flex items-center ml-auto sm:ml-0">
            <li className="mr-2 ">
              <a href={contactInfo.socialMedia.instagram} target="__blank">
                <Instagram />
              </a>
            </li>
            <li className="mr-4 ">
              <a href={contactInfo.socialMedia.facebook} target="__blank">
                <Facebook />
              </a>
            </li>
            <li>
              <Button
                component={Link}
                to="/contact/"
                className="rounded-none text-xs font-semibold h-14"
                variant="contained"
                color="primary"
                size="large"
                endIcon={<ArrowRightAlt />}
                disableElevation
              >
                Get a quote
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderNotice;
