import { Drawer } from "@material-ui/core";
import { Link } from "gatsby";
import React from "react";

interface MobileDrawerProps {
  showMobileNav: boolean;
  handleCloseMobileNav: () => void;
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({
  showMobileNav = false,
  handleCloseMobileNav,
}) => {
  return (
    <>
      {showMobileNav ? (
        <div
          className="absolute inset-0 z-[100] w-full h-full bg-black bg-opacity-40"
          onClick={handleCloseMobileNav}
        />
      ) : null}
      <div
        className={`fixed top-0 right-0 z-[150] h-full w-72 flex flex-col justify-center bg-gray-50 shadow-2xl transform ${
          showMobileNav ? "translate-x-0" : "translate-x-[100%]"
        } ease-linear transition-transform duration-200`}
      >
        <ul className="px-8 transform translate-y-[-25%]">
          <ListItem to="/">Home</ListItem>
          <ListItem to="/about/">About Us</ListItem>
          <ListItem to="/services/">Services</ListItem>
          <ListItem to="/projects/">Projects</ListItem>
          <ListItem to="/contact/">Contact</ListItem>
        </ul>
      </div>
    </>
  );
};

export default MobileDrawer;

interface ListItemProps {
  to: string;
}

const ListItem: React.FC<ListItemProps> = ({ to, children }) => {
  return (
    <li className="py-4 px-6 text-center">
      <Link
        className="font-medium text-text-heading hover:text-primary-main"
        activeClassName="text-primary-main"
        to={to}
      >
        {children}
      </Link>
    </li>
  );
};
